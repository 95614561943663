define(['app', '$window', 'enhancedEcom', 'siteObj'], (app, window, enhancedEcom, siteObj) => {

  const athenaProductPage = () => {
    const component = {};

    const _config = {
      enhancedEcomUpdate :'enhancedEcom/update',
      productPageStockAvailabilityupdate: 'productPageAvailabilityUpdate/update',
      externalSku: '.externalSku',
      brandLogo: '.athenaProductPage_productBrandLogo',
      priceAmount: '.productPrice_priceAmount',
      productPageStockAvailabilityComp: '.productPageStockAvailability',
      productAddToBasket: '.productAddToBasket',
      clickAndCollectDisplay: '.clickAndCollectDisplayModal',
      stickyDeliveryAndClickAndCollectActions: '.athenaProductPage_actions_stickyDeliveryAndClickAndCollectActions',
      addToBasketButton: '.athenaProductPage_productAddToBasket'
    };

    const _attrib = {
      homeDelivery: 'data-home-delivery',
      inStock: 'data-in-stock',
      inStockWarehouse: 'data-in-stock-warehouse',
      clickAndCollect: 'data-click-and-collect',
      checkStock: 'data-check-stock',
      noCheckStock: 'data-no-check-stock',
      orderInStore: 'data-order-in-store',
      externalSku: 'data-external-sku',
      brand: 'data-product-brand',
      range: 'data-product-range',
      hasEnhancedEcommerce: 'data-enhanced-ecom',
      hasTPSSAvailability: 'data-tpss-availability',
      category: 'data-product-category',
      collectionType: 'data-product-collection-type',
      videoReference: 'data-product-video-reference',
      productStarRating: 'data-product-star-rating',
      productImages: 'data-product-images',
      stickyButtonCC:'[cta-sticky-button-cc]',
      stickyButtonDelivery: '[cta-sticky-button-delivery]'
    };
    const _init = (element) => {

      component.element = element;
      component.hasEnhancedEcommerce = component.element.hasAttribute(component.attrib.hasEnhancedEcommerce);
      component.hasTPSSAvailability = component.element.hasAttribute(component.attrib.hasTPSSAvailability);

      app.subscribe(component.config.productPageStockAvailabilityupdate, component.addEventListeners);

      if(component.hasEnhancedEcommerce){
        app.subscribe(component.config.enhancedEcomUpdate, component.enhancedEcomUpdate);
        component.enhancedEcomUpdate();
      }

      if(component.hasTPSSAvailability) {
        component.checkTPSSAvailability();
      }

      if(siteObj.config.useGa4EnhancedEcom === true) {
        ga4PageView();
      }

      component.addEventListeners();

      const productCategory = component.element.getAttribute('data-product-category');
      const productVideoReference = component.element.getAttribute('data-product-video-reference');
      const productStarRating = component.element.getAttribute('data-product-star-rating');
      const productImages = component.element.getAttribute('data-product-images');

      if (new URLSearchParams(window.location.search).get('addToBasketExclusive') === 'true') {
        window.addEventListener('load', () => {
          app.publish('addProductToBasket/exclusive');
          window.history.pushState({}, document.title, window.location.pathname);
        })
      }

      const dataLayerObject = {
        'productBrand': siteObj.productBrand,
        'productCategory': productCategory,
        'productVideoReference': productVideoReference,
        'productStarRating': productStarRating,
        'productImages': productImages,
        'platform': 'Elysium1'
      }
      window.dataLayer[0].productDetails.push(dataLayerObject);
      return component;
    };

    const ga4PageView = () => {
      const productTitle = siteObj.productTitle.toString().replace(/&#039;|'/g, "");

      const {item_variation_id, productCategory, price, savingAmount} = component.getPdpData();
      const itemsObjectArray = [{
        'item_name': productTitle,
        'item_id': siteObj.productID.toString(),
        'price': price,
        'item_brand': siteObj.productBrand,
        'item_category': productCategory,
        'item_variation': item_variation_id !== siteObj.productID ? item_variation_id : '', 
        'discount': savingAmount
      }];

      app.publish('ga4tracking/record', 'ecom_event', 'view_item', siteObj.currencyType, itemsObjectArray);
    }

    const _getPdpData = () => {
      const productCategory = component.element.getAttribute('data-product-category');
      const price = siteObj.productPrice.split(';')[1] || siteObj.productPrice;
      const savingAmount = component.getSavingAmount();
      const addToBasketButton  = component.element.querySelector('[data-product-id]');
      const addToBasketProductId = 'dataset' in addToBasketButton ? addToBasketButton.dataset.productId : '';
      const item_variation_id = siteObj.productID !== addToBasketProductId ? addToBasketProductId : siteObj.productID;


      return {
        item_variation_id,
        productCategory,
        price,
        savingAmount
      }
    }

    const _getSavingAmount = () => {

      const savingAmountClass = component.element.querySelector('.productPrice_savingAmount');

      let savingAmount = '';

      if(savingAmountClass) {
        savingAmount = savingAmountClass.getAttribute('data-product-saving');
        savingAmount =  savingAmount.replace(/[^0-9.]/g, '');
        savingAmount.trim();
      }

      return savingAmount
    }
    const _enhancedEcomUpdate = () => {
      const stockAvailability = component.element.querySelector(component.config.productPageStockAvailabilityComp);
      const productBrand = siteObj.productBrand;
      const productRange = siteObj.productRange;


      let homeDeliveryAvailable = '';
      let inStock = '';
      let inStockWarehouse = '';
      let clickAndCollect = '';
      let checkStock = '';
      let noCheckStock = '';
      let collectionType = '';
      let category = '';


      if (stockAvailability) {
        homeDeliveryAvailable = stockAvailability.getAttribute(component.attrib.homeDelivery);
        inStock = stockAvailability.getAttribute(component.attrib.inStock);
        inStockWarehouse = stockAvailability.getAttribute(component.attrib.inStockWarehouse);
        clickAndCollect = stockAvailability.getAttribute(component.attrib.clickAndCollect);
        checkStock = stockAvailability.getAttribute(component.attrib.checkStock);
        noCheckStock = stockAvailability.getAttribute(component.attrib.noCheckStock);
        collectionType = stockAvailability.getAttribute(component.attrib.collectionType);
        category = stockAvailability.getAttribute(component.attrib.category);
      }

      let sku = siteObj.productID;
      const externalSku = component.element.querySelector(component.config.externalSku);
      if (externalSku) {
        sku = externalSku.getAttribute(component.attrib.externalSku);
      }

      const productName = siteObj.productTitle;
      const productPrice = component.element.querySelector(component.config.priceAmount).innerHTML;

      enhancedEcom.productView(productName, sku, productPrice, productBrand, inStock, inStockWarehouse,
        homeDeliveryAvailable, clickAndCollect, checkStock, noCheckStock, collectionType, category, productRange);
    }

    const _checkTPSSAvailability = () => {
      app.ajax.get({
        url: `${window.location.origin}/thirdPartyStoresAvailable.lookup`,
        timeout: 10000,
        success: (response) => {
          if(response) {
            if (component.element.querySelector('.productThirdPartyStoreFinder') !== null) {
              component.element.querySelector('.productThirdPartyStoreFinder').style.display = 'block';
            }
          }
        },
        error: (error) => {
          console.log(error)
        }
      });
    }

    const _addEventListeners = function() {
      const stickyClickAndCollect = component.element.querySelector(component.attrib.stickyButtonCC);
      const stickyClickAndCollectButton = stickyClickAndCollect && stickyClickAndCollect.querySelector(component.config.clickAndCollectDisplay);

      const stickyDelivery = component.element.querySelector(component.attrib.stickyButtonDelivery);
      const stickyDeliveryButton = stickyDelivery && stickyDelivery.querySelector(component.config.productAddToBasket);

      if (component.element && siteObj.type === 'product' && (stickyDeliveryButton || stickyClickAndCollectButton
        || component.element.querySelector(component.config.stickyDeliveryAndClickAndCollectActions))) {
        window.addEventListener('scroll', component.stickyEvent);
        component.stickyEvent();
      }
    }

    const _stickyEvent = function() {
      const addToBasketWrapper = document.querySelectorAll(component.config.addToBasketButton)[0];
      const addToBasket = addToBasketWrapper && addToBasketWrapper.querySelector(component.config.productAddToBasket);
      const clickAndCollectWrapper = document.querySelectorAll(component.config.clickAndCollectDisplay)[0];

      if(addToBasket) {
        component.stickyButtonEvent(addToBasketWrapper);
      } else if (clickAndCollectWrapper) {
        component.stickyButtonEvent(clickAndCollectWrapper);
      }
    }

    const _stickyButtonEvent = function(stickyBtnWrapper) {
      if (stickyBtnWrapper) {
        const windowHeight = window.innerHeight;
        const stickPositionTop = stickyBtnWrapper.getBoundingClientRect().top;
        const stickPositionBottom = stickyBtnWrapper.getBoundingClientRect().bottom;
        //Outside of Viewport
        if ((stickPositionTop < 0 || stickPositionTop > windowHeight)) {
          document.querySelector(component.config.stickyDeliveryAndClickAndCollectActions).classList.remove('hide');
        }
        //Inside of viewport
        else if (stickPositionTop > 0 && stickPositionBottom <= windowHeight) {
          document.querySelector(component.config.stickyDeliveryAndClickAndCollectActions).classList.add('hide');
        }
      }
    }

    component.config = _config;
    component.attrib = _attrib;
    component.init = _init;
    component.enhancedEcomUpdate = _enhancedEcomUpdate;
    component.checkTPSSAvailability = _checkTPSSAvailability;
    component.stickyButtonEvent = _stickyButtonEvent;
    component.addEventListeners = _addEventListeners;
    component.stickyEvent = _stickyEvent;
    component.getPdpData = _getPdpData;
    component.getSavingAmount = _getSavingAmount;

    return component;
  };

  return athenaProductPage;
});
